import config from "@/config"
import http from "@/utils/request"

export default {
	wage: {
		listWage: {
			url: `${config.API_URL}/erp/wage/list`,
			name: "查询工价管理列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getWage: {
			url: `${config.API_URL}/erp/wage/`,
			name: "查询工价管理详细",
			get: async function(wageId) {
				return await http.get(this.url +wageId);
			}
		},
		addWage: {
			url: `${config.API_URL}/erp/wage`,
			name: "新增工价管理",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateWage: {
			url: `${config.API_URL}/erp/wage`,
			name: "修改工价管理",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delWage: {
			url: `${config.API_URL}/erp/wage/`,
			name: "删除工价管理",
			delete: async function(wageId) {
				return await http.delete(this.url +wageId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/wage/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`wage`");
			}
		},
	},
	wageLine: {
		listWageLine: {
			url: `${config.API_URL}/erp/wageLine/list`,
			name: "查询工价信息列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getWageLine: {
			url: `${config.API_URL}/erp/wageLine/`,
			name: "查询工价信息详细",
			get: async function(lineId) {
				return await http.get(this.url +lineId);
			}
		},
		addWageLine: {
			url: `${config.API_URL}/erp/wageLine`,
			name: "新增工价信息",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateWageLine: {
			url: `${config.API_URL}/erp/wageLine`,
			name: "修改工价信息",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delWageLine: {
			url: `${config.API_URL}/erp/wageLine/`,
			name: "删除工价信息",
			delete: async function(lineId) {
				return await http.delete(this.url +lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/wageLine/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`wageLine`");
			}
		},
	},
	reportStatistics: {
		listReportStatistics: {
			url: `${config.API_URL}/erp/reportStatistics/list`,
			name: "查询员工报工统计列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getReportStatistics: {
			url: `${config.API_URL}/erp/reportStatistics/`,
			name: "查询员工报工统计详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		addReportStatistics: {
			url: `${config.API_URL}/erp/reportStatistics`,
			name: "新增员工报工统计",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateReportStatistics: {
			url: `${config.API_URL}/erp/reportStatistics`,
			name: "修改员工报工统计",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delReportStatistics: {
			url: `${config.API_URL}/erp/reportStatistics/`,
			name: "删除员工报工统计",
			delete: async function(id) {
				return await http.delete(this.url +id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/reportStatistics/export`,
			name: "导出附件",
			post: async function (data, title, config = {}) {
				return await http.postDom(this.url, data, config, title || "`reportStatistics`");
			}
		},
	},
	salaryStatistics: {
		listByMonthAndTeam: {
			url: `${config.API_URL}/erp/salaryStatistics/listByMonthAndTeam`,
			name: "查询工资统计列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		listSalaryStatistics: {
			url: `${config.API_URL}/erp/salaryStatistics/list`,
			name: "查询员工工资统计列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getSalaryStatistics: {
			url: `${config.API_URL}/erp/salaryStatistics/`,
			name: "查询员工工资统计详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		addSalaryStatistics: {
			url: `${config.API_URL}/erp/salaryStatistics`,
			name: "新增员工工资统计",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateSalaryStatistics: {
			url: `${config.API_URL}/erp/salaryStatistics`,
			name: "修改员工工资统计",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delSalaryStatistics: {
			url: `${config.API_URL}/erp/salaryStatistics/`,
			name: "删除员工工资统计",
			delete: async function(id) {
				return await http.delete(this.url +id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/erp/salaryStatistics/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`工资表`");
			}
		},
	}
}
