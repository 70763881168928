import config from "@/config"
import http from "@/utils/request"

export default {
	bom: {
		listBom: {
			url: `${config.API_URL}/mes/md/bom/list`,
			name: "查询产品BOM关系列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listBomGroupByItem: {
			url: `${config.API_URL}/mes/md/bom/listGroupByItem`,
			name: "查询产品BOM关系列表按产品分类",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getBom: {
			url: `${config.API_URL}/mes/md/bom/`,
			name: "查询产品BOM关系详细",
			get: async function (bomId) {
				return await http.get(this.url + bomId);
			}
		},
		addBom: {
			url: `${config.API_URL}/mes/md/bom`,
			name: "新增产品BOM关系",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateBom: {
			url: `${config.API_URL}/mes/md/bom`,
			name: "修改产品BOM关系",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delBom: {
			url: `${config.API_URL}/mes/md/bom/`,
			name: "删除产品BOM关系",
			delete: async function (bomId) {
				return await http.delete(this.url + bomId);
			}
		},
		getBomTre: {
			url: `${config.API_URL}/mes/md/bom/getBomTreeByBomId/`,
			name: "查询产品BOM树详细",
			get: async function (bomId) {
				return await http.get(this.url + bomId);
			}
		},
		exportTreeFile: {
			url: `${config.API_URL}/mes/md/bom/exportBomTree`,
			name: "导出",
			post: async function (data,config = {}) {
				return await http.postDom(this.url, data, config, "client");
			}
		},
		getBomChangeRecord: {
			url: `${config.API_URL}/mes/md/bom/getBomChangeRecord/`,
			name: "查询产品BOM变更记录",
			get: async function (bomId) {
				return await http.get(this.url + bomId);
			}
		},
	},
	bomItem: {
		listBomItem: {
			url: `${config.API_URL}/mes/md/bomItem/list`,
			name: "查询BOM物料配件列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		detailListBomItem: {
			url: `${config.API_URL}/mes/md/bomItem/detailList`,
			name: "查询BOM物料配件详情列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getBomItem: {
			url: `${config.API_URL}/mes/md/bomItem/`,
			name: "查询BOM物料配件详细",
			get: async function (bomItemId) {
				return await http.get(this.url + bomItemId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/md/bomItem/export`,
			name: "导出",
			post: async function (data,config = {}) {
				return await http.postDom(this.url, data, config, "client");
			}
		},
	},
	bomProcessItem: {
		listBomProcessItem: {
			url: `${config.API_URL}/mes/md/bomProcessItem/list`,
			name: "查询BOM工序物料配件列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getBomProcessItem: {
			url: `${config.API_URL}/mes/md/bomProcessItem/`,
			name: "查询BOM工序物料配件详细",
			get: async function (bomItemId) {
				return await http.get(this.url + bomItemId);
			}
		},
		getTaskUseMaterialList: {
			url: `${config.API_URL}/mes/md/bomProcessItem/getTaskUseMaterialList`,
			name: "查询任务用料",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/md/bomProcessItem/export`,
			name: "导出",
			post: async function (data,config = {}) {
				return await http.postDom(this.url, data, config, "client");
			}
		},
	},
	client: {
		listClient: {
			url: `${config.API_URL}/mes/md/client/list`,
			name: "查询客户列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getClient: {
			url: `${config.API_URL}/mes/md/client/`,
			name: "查询客户详细",
			get: async function (clientId) {
				return await http.get(this.url + clientId);
			}
		},
		addClient: {
			url: `${config.API_URL}/mes/md/client`,
			name: "新增客户",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateClient: {
			url: `${config.API_URL}/mes/md/client`,
			name: "修改客户",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delClient: {
			url: `${config.API_URL}/mes/md/client/`,
			name: "删除客户",
			delete: async function (clientId) {
				return await http.delete(this.url + clientId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/md/client/export`,
			name: "导出",
			post: async function (data,config = {}) {
				return await http.postDom(this.url, data, config, "client");
			}
		},
	},
	itemtype: {
		listItemType: {
			url: `${config.API_URL}/mes/md/itemtype/list`,
			name: "查询产品物料类型列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listItemTypeExcludeChild: {
			url: `${config.API_URL}/mes/md/itemtype/list/exclude`,
			name: "查询产品物料类型（排除节点）",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getItemType: {
			url: `${config.API_URL}/mes/md/itemtype/`,
			name: "查询产品物料类型详细",
			get: async function (itemTypeId) {
				return await http.get(this.url + itemTypeId);
			}
		},
		treeselect: {
			url: `${config.API_URL}/mes/md/itemtype/treeselect`,
			name: "查询产品物料类型下拉树结构",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		addItemType: {
			url: `${config.API_URL}/mes/md/itemtype`,
			name: "新增产品物料类型",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateItemType: {
			url: `${config.API_URL}/mes/md/itemtype`,
			name: "修改产品物料类型",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delItemType: {
			url: `${config.API_URL}/mes/md/itemtype/`,
			name: "修改产品物料类型",
			delete: async function (itemTypeId) {
				return await http.delete(this.url + itemTypeId);
			}
		},
		listSecondItemType: {
			url: `${config.API_URL}/mes/md/itemtype/listSecondItemType/`,
			name: "根据分类名称，查询该分类的所有下一级分类",
			get: async function (itemTypeName) {
				return await http.get(this.url + itemTypeName);
			}
		},
	},
	mditem: {
		listMdItem: {
			url: `${config.API_URL}/mes/md/mditem/list`,
			name: "查询物料列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		notLikeThisItemList: {
			url: `${config.API_URL}/mes/md/mditem/notLikeThisItemList`,
			name: "查询不包含该产品的物料列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getMdItem: {
			url: `${config.API_URL}/mes/md/mditem/`,
			name: "查询物料详细",
			get: async function (itemId) {
				return await http.get(this.url + itemId);
			}
		},
		addMdItem: {
			url: `${config.API_URL}/mes/md/mditem`,
			name: "新增物料",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateMdItem: {
			url: `${config.API_URL}/mes/md/mditem`,
			name: "修改物料",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delMdItem: {
			url: `${config.API_URL}/mes/md/mditem/`,
			name: "删除物料",
			delete: async function (itemId) {
				return await http.delete(this.url + itemId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/md/mditem/export`,
			name: "导出",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "mditem");
			}
		},
		importTemplate: {
			url: `${config.API_URL}/mes/md/mditem/importTemplate`,
			name: "下载模板",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "mditemImport");
			}
		},
	},
	sop: {
		listSop: {
			url: `${config.API_URL}/mes/md/sop/list`,
			name: "查询产品SOP列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getSop: {
			url: `${config.API_URL}/mes/md/sop/`,
			name: "查询产品SOP详细",
			get: async function (sopId) {
				return await http.get(this.url + sopId);
			}
		},
		addSop: {
			url: `${config.API_URL}/mes/md/sop`,
			name: "新增产品SOP",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateSop: {
			url: `${config.API_URL}/mes/md/sop`,
			name: "修改产品SOP",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delSop: {
			url: `${config.API_URL}/mes/md/sop/`,
			name: "删除产品SOP",
			delete: async function (sopId) {
				return await http.delete(this.url + sopId);
			}
		},
		getLatestSop: {
			url: `${config.API_URL}/mes/md/sop/getLatestSop/`,
			name: "根据工序id查询最新的sop",
			get: async function (processId) {
				return await http.get(this.url + processId);
			}
		}
	},
	unitmeasure: {
		listUnitmeasure: {
			url: `${config.API_URL}/mes/md/unitmeasure/list`,
			name: "查询单位列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listPrimaryUnitmeasure: {
			url: `${config.API_URL}/mes/md/unitmeasure/listprimary`,
			name: "查询单位列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listAllUnitmeasure: {
			url: `${config.API_URL}/mes/md/unitmeasure/selectall`,
			name: "查询单位列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getUnitmeasure: {
			url: `${config.API_URL}/mes/md/unitmeasure/`,
			name: "查询单位详细",
			get: async function (measureId) {
				return await http.get(this.url + measureId);
			}
		},
		addUnitmeasure: {
			url: `${config.API_URL}/mes/md/unitmeasure`,
			name: "新增单位",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateUnitmeasure: {
			url: `${config.API_URL}/mes/md/unitmeasure`,
			name: "修改单位",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delUnitmeasure: {
			url: `${config.API_URL}/mes/md/unitmeasure/`,
			name: "删除单位",
			delete: async function (measureId) {
				return await http.delete(this.url + measureId);
			}
		},
		exportFile: {
			url: `${config.API_URL}mes/md/unitmeasure/export`,
			name: "导出",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "unitmeasure");
			}
		},
	},
	vendor: {
		listVendor: {
			url: `${config.API_URL}/mes/md/vendor/list`,
			name: "查询供应商列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getDept: {
			url: `${config.API_URL}/mes/md/vendor/`,
			name: "查询供应商详细",
			get: async function (vendorId) {
				return await http.get(this.url + vendorId);
			}
		},
		getVendorList: {
			url: `${config.API_URL}/mes/md/vendor/getVendorList`,
			name: "根据物料id查找对应的供应商",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		addVendor: {
			url: `${config.API_URL}/mes/md/vendor`,
			name: "新增供应商",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateVendor: {
			url: `${config.API_URL}/mes/md/vendor`,
			name: "修改供应商",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delVendor: {
			url: `${config.API_URL}/mes/md/vendor/`,
			name: "删除供应商",
			delete: async function (vendorId) {
				return await http.delete(this.url + vendorId);
			}
		},
		exportFile: {
			url: `${config.API_URL}mes/md/vendor/export`,
			name: "导出",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "vendor");
			}
		},
		importTemplate: {
			url: `${config.API_URL}mes/md/vendor/importTemplate`,
			name: "下载模板",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "vendorImport");
			}
		},
	},
	workshop: {
		listWorkshop: {
			url: `${config.API_URL}/mes/md/workshop/list`,
			name: "查询车间列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listAllWorkshop: {
			url: `${config.API_URL}/mes/md/workshop/listAll`,
			name: "查询车间列表",
			get: async function () {
				return await http.get(this.url);
			}
		},
		getWorkshop: {
			url: `${config.API_URL}/mes/md/workshop/`,
			name: "查询车间详细",
			get: async function (workshopId) {
				return await http.get(this.url + workshopId);
			}
		},
		addWorkshop: {
			url: `${config.API_URL}/mes/md/workshop`,
			name: "新增车间",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWorkshop: {
			url: `${config.API_URL}/mes/md/workshop`,
			name: "修改车间",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWorkshop: {
			url: `${config.API_URL}/mes/md/workshop/`,
			name: "删除车间",
			delete: async function (workshopId) {
				return await http.delete(this.url + workshopId);
			}
		},
		exportFile: {
			url: `${config.API_URL}mes/md/workshop/export`,
			name: "导出",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "workshop");
			}
		},
	},
	workstation: {
		listWorkstation: {
			url: `${config.API_URL}/mes/md/workstation/list`,
			name: "查询工作站列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getWorkstation: {
			url: `${config.API_URL}/mes/md/workstation/`,
			name: "查询工作站详细",
			get: async function (workstationId) {
				return await http.get(this.url + workstationId);
			}
		},
		addWorkstation: {
			url: `${config.API_URL}/mes/md/workstation`,
			name: "新增工作站",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWorkstation: {
			url: `${config.API_URL}/mes/md/workstation`,
			name: "修改工作站",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWorkstation: {
			url: `${config.API_URL}/mes/md/workstation/`,
			name: "删除工作站",
			delete: async function (workstationId) {
				return await http.delete(this.url + workstationId);
			}
		},
		exportFile: {
			url: `${config.API_URL}mes/md/workstation/export`,
			name: "导出",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "workstation");
			}
		},
	},
	workstationmachine: {
		listWorkstationmachine: {
			url: `${config.API_URL}/mes/md/workstationmachine/list`,
			name: "查询设备资源列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getWorkstationmachine: {
			url: `${config.API_URL}/mes/md/workstationmachine/`,
			name: "查询设备资源详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addWorkstationmachine: {
			url: `${config.API_URL}/mes/md/workstationmachine`,
			name: "新增设备资源",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWorkstationmachine: {
			url: `${config.API_URL}/mes/md/workstationmachine`,
			name: "修改设备资源",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWorkstationmachine: {
			url: `${config.API_URL}/mes/md/workstationmachine/`,
			name: "删除设备资源",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},
	},
	workstationtool: {
		listWorkstationtool: {
			url: `${config.API_URL}/mes/md/workstationtool/list`,
			name: "查询工装夹具资源列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getWorkstationtool: {
			url: `${config.API_URL}/mes/md/workstationtool/`,
			name: "查询工装夹具资源详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addWorkstationtool: {
			url: `${config.API_URL}/mes/md/workstationtool`,
			name: "新增工装夹具资源",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWorkstationtool: {
			url: `${config.API_URL}/mes/md/workstationtool`,
			name: "修改工装夹具资源",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWorkstationtool: {
			url: `${config.API_URL}/mes/md/workstationtool/`,
			name: "删除工装夹具资源",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},
	},
	workstationworker: {
		listWorkstationworker: {
			url: `${config.API_URL}/mes/md/workstationworker/list`,
			name: "查询人力资源列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getWorkstationworker: {
			url: `${config.API_URL}/mes/md/workstationworker/`,
			name: "查询人力资源详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addWorkstationworker: {
			url: `${config.API_URL}/mes/md/workstationworker`,
			name: "新增人力资源",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWorkstationworker: {
			url: `${config.API_URL}/mes/md/workstationworker`,
			name: "修改人力资源",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWorkstationworker: {
			url: `${config.API_URL}/mes/md/workstationworker/`,
			name: "删除人力资源",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},

	},
	unitQuantitySetting: {
		listSetting: {
			url: `${config.API_URL}/mes/md/setting/list`,
			name: "查询板材每垛数量设置列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getSetting: {
			url: `${config.API_URL}/mes/md/setting/`,
			name: "查询板材每垛数量设置详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		addSetting: {
			url: `${config.API_URL}/mes/md/setting`,
			name: "新增板材每垛数量设置",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateSetting: {
			url: `${config.API_URL}/mes/md/setting`,
			name: "修改板材每垛数量设置",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delSetting: {
			url: `${config.API_URL}/mes/md/setting/`,
			name: "删除板材每垛数量设置",
			delete: async function(id) {
				return await http.delete(this.url +id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/md/setting/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "板材每垛数量设置");
			}
		},
	}
}
