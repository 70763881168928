import axios from 'axios';
import {ElNotification, ElMessageBox, ElMessage} from 'element-plus';
import sysConfig from "@/config";
import tool from '@/utils/tool';
import router from '@/router';
import {saveAs} from 'file-saver'
import errorCode from '@/utils/errorCode'

axios.defaults.baseURL = ''

axios.defaults.timeout = sysConfig.TIMEOUT

// HTTP request 拦截器
axios.interceptors.request.use(
	(config) => {
		let token = tool.cookie.get("TOKEN");
		if (token) {
			config.headers[sysConfig.TOKEN_NAME] = sysConfig.TOKEN_PREFIX + token
		}
		if (!sysConfig.REQUEST_CACHE && config.method == 'get') {
			if (config.params && config.params.params) {
				let result = ''
				for (const propName of Object.keys(config.params)) {
					const value = config.params[propName];
					let part = encodeURIComponent(propName) + "=";
					if (value !== null && typeof (value) !== "undefined") {
						if (typeof value === 'object') {
							for (const key of Object.keys(value)) {
								if (value[key] !== null && typeof (value[key]) !== 'undefined') {
									let params = propName + '[' + key + ']';
									let subPart = encodeURIComponent(params) + "=";
									result += subPart + encodeURIComponent(value[key]) + "&";
								}
							}
						} else {
							result += part + encodeURIComponent(value) + "&";
						}
					}
				}
				let url = config.url + '?' + result;
				url = url.slice(0, -1);
				config.params = {};
				config.url = url;
			} else {
				config.params = config.params || {};
			}
			config.params['_'] = new Date().getTime();
		}
		Object.assign(config.headers, sysConfig.HEADERS)
		// console.log("config====",config)
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// 是否显示重新登录，多个API同时401时疯狂弹窗BUG
export let isRelogin = {show: false};

// HTTP response 拦截器
axios.interceptors.response.use(
	(response) => {
		// 未设置状态码则默认成功状态
		const code = response.data.code || 200;
		// 获取错误信息
		const msg = errorCode[code] || response.data.msg || errorCode['default']
		// 二进制数据则直接返回
		if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
			return response;
		}
		if (code === 401) {
			if (!isRelogin.show) {
				isRelogin.show = true;
				ElMessageBox.confirm('当前用户已被登出或无权限访问当前资源，请尝试重新登录后再操作。', '无权限访问', {
					type: 'error',
					closeOnClickModal: false,
					center: true,
					confirmButtonText: '重新登录',
					beforeClose: (action, instance, done) => {
						isRelogin.show = false;
						done()
					}
				}).then(() => {
					isRelogin.show = false;
					router.replace({path: '/login'});
				}).catch(() => {
					isRelogin.show = false;
				});
			}
			return response;
		} else if (code === 403) {
			ElMessage({
				message: msg,
				type: 'error'
			})
			return response;
		} else if (code === 500) {
			ElMessage({
				message: msg,
				type: 'error'
			})
			return response;
		} else {
			return response;
		}
	},
	(error) => {
		const status = error.response?.status
		if (status == 404) {
			ElNotification.error({
				title: '请求错误',
				message: "Status:404，正在请求不存在的服务器记录！"
			});
		} else if (status == 401) {
			if (!isRelogin.show) {
				isRelogin.show = true
				ElMessageBox.confirm('当前用户已被登出或无权限访问当前资源，请尝试重新登录后再操作。', '无权限访问', {
					type: 'error',
					closeOnClickModal: false,
					center: true,
					confirmButtonText: '重新登录',
					beforeClose: (action, instance, done) => {
						isRelogin.show = false
						done()
					}
				}).then(() => {
					router.replace({path: '/login'});
				}).catch(() => {
				})
			}
			return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
		} else if (status == 500) {
			ElNotification.error({
				title: '请求错误',
				message: error.response.data.message || "Status:500，服务器发生错误！"
			});
		} else if (status == 503) {
			ElNotification.error({
				title: '小金迷路了，请稍后再试！',
				message: error.message || `Status:${error.response.status}，未知错误！`
			});
		} else {
			ElNotification.error({
				title: '小金出错了，请联系系统管理员！',
				message: error.message || `Status:${error.response.status}，未知错误！`
			});
		}
		return {code: 300, msg: error.message || '未知错误！', data: []};
	}
);

var http = {

	/** get 请求
	 * @param  {string} url 接口地址
	 * @param  {object} params 请求参数
	 * @param  {object} config 参数
	 */
	get: function (url, params = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: url,
				params: params,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** post 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	post: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** put 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	put: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'put',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** patch 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	patch: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'patch',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** delete 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	delete: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'delete',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** jsonp 请求
	 * @param  {string} url 接口地址
	 * @param  {string} name JSONP回调函数名称
	 */
	jsonp: function (url, name = 'jsonp') {
		return new Promise((resolve) => {
			var script = document.createElement('script')
			var _id = `jsonp${Math.ceil(Math.random() * 1000000)}`
			script.id = _id
			script.type = 'text/javascript'
			script.src = url
			window[name] = (response) => {
				resolve(response)
				document.getElementsByTagName('head')[0].removeChild(script)
				try {
					delete window[name];
				} catch (e) {
					window[name] = undefined;
				}
			}
			document.getElementsByTagName('head')[0].appendChild(script)
		})
	},
	/** post 下载请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	postDom: function (url, data = {}, config = {}, type) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: url,
				data: data,
				responseType: 'blob',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				...config,
			}).then((response) => {
				const blob = new Blob([response.data])
				saveAs(blob, `${type}_` + `${new Date().getTime()}.xlsx`)
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},
	/** post 下载请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	postDom1: function (url, data = {}, config = {}, type) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: url,
				data: data,
				responseType: 'blob',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				...config,
			}).then((response) => {
				const blob = new Blob([response.data])
				saveAs(blob, `${type}` + `.xlsx`)
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},
	/** get 下载请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	getDom: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: url,
				params: data,
				responseType: 'blob',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				...config,
			}).then((response) => {
				const blob = new Blob([response.data])
				saveAs(blob, `${data.name}`)
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},
	downloadZip: function (url, data = {}, config = {}, type) {
	return new Promise((resolve, reject) => {
		axios({
			method: 'get',
			url: url,
			data: data,
			responseType: 'blob',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			...config,
		}).then((response) => {
			const blob = new Blob([response.data], { type: 'application/zip' })
			saveAs(blob, `${type}_` + `${new Date().getTime()}.zip`)
			resolve(response.data);
		}).catch((error) => {
			reject(error);
		})
	})
},
}

export default http;

// 转换字符串，undefined,null等转化为""
export function parseStrEmpty(str) {
	if (!str || str == "undefined" || str == "null") {
		return "";
	}
	return str;
}

