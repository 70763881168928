import config from "@/config"
import http from "@/utils/request"

export default {
	feedback: {
		listFeedback: {
			url: `${config.API_URL}/mes/pro/feedback/list`,
			name: "查询生产报工记录列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getFeedback: {
			url: `${config.API_URL}/mes/pro/feedback/`,
			name: "查询生产报工记录详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addFeedback: {
			url: `${config.API_URL}/mes/pro/feedback`,
			name: "新增生产报工记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateFeedback: {
			url: `${config.API_URL}/mes/pro/feedback`,
			name: "修改生产报工记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delFeedback: {
			url: `${config.API_URL}/mes/pro/feedback/`,
			name: "删除生产报工记录",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId, recordId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/pro/feedback/`,
			name: " ",
			put: async function (recordId) {
				return await http.put(this.url+recordId);
			}
		},
		getByTaskId: {
			url: `${config.API_URL}/mes/pro/feedback/getByTaskId/`,
			name: "查询生产报工记录详细",
			get: async function (taskId) {
				return await http.get(this.url + taskId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/feedback/export`,
			name: "导出报工记录",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_feedback");
			}
		},
		getByReportItemId: {
			url: `${config.API_URL}/mes/pro/feedback/getByReportItemId/`,
			name: "查询生产报工记录详细",
			get: async function (reportItemId) {
				return await http.get(this.url + reportItemId);
			}
		},
	},
	process: {
		listProcess: {
			url: `${config.API_URL}/mes/pro/process/list`,
			name: "查询生产工序列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listAllProcess: {
			url: `${config.API_URL}/mes/pro/process/listAll`,
			name: "查询所有有效生产工序",
			get: async function () {
				return await http.get(this.url);
			}
		},
		getProcessDetail: {
			url: `${config.API_URL}/mes/pro/process/`,
			name: "查询生产工序详细",
			get: async function (processId) {
				return await http.get(this.url + processId);
			}
		},
		addProcess: {
			url: `${config.API_URL}/mes/pro/process`,
			name: "新增生产工序",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProcess: {
			url: `${config.API_URL}/mes/pro/process`,
			name: "修改生产工序",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProcess: {
			url: `${config.API_URL}/mes/pro/process/`,
			name: "删除生产工序",
			delete: async function (processId) {
				return await http.delete(this.url + processId, processId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/process/export`,
			name: "导出生产工序",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_process");
			}
		}
	},
	processcontent: {
		listProcesscontent: {
			url: `${config.API_URL}/mes/pro/processcontent/list`,
			name: "查询生产工序内容列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getProcesscontent: {
			url: `${config.API_URL}/mes/pro/processcontent/`,
			name: "查询生产工序内容详细",
			get: async function (contentId) {
				return await http.get(this.url + contentId);
			}
		},
		addProcesscontent: {
			url: `${config.API_URL}/mes/pro/processcontent`,
			name: "新增生产工序内容",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProcesscontent: {
			url: `${config.API_URL}/mes/pro/processcontent`,
			name: "修改生产工序内容",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProcesscontent: {
			url: `${config.API_URL}/mes/pro/processcontent/`,
			name: "删除生产工序内容",
			delete: async function (contentId) {
				return await http.delete(this.url + contentId, contentId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/processcontent/export`,
			name: "导出生产工序步骤",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_process_content");
			}
		}
	},
	proroute: {
		listProroute: {
			url: `${config.API_URL}/mes/pro/proroute/list`,
			name: "查询工艺路线列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getProroute: {
			url: `${config.API_URL}/mes/pro/proroute/`,
			name: "查询工艺路线详细",
			get: async function (routeId) {
				return await http.get(this.url + routeId);
			}
		},
		addProroute: {
			url: `${config.API_URL}/mes/pro/proroute`,
			name: "新增工艺路线",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProroute: {
			url: `${config.API_URL}/mes/pro/proroute`,
			name: "修改工艺路线",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProroute: {
			url: `${config.API_URL}/mes/pro/proroute/`,
			name: "删除工艺路线",
			delete: async function (routeId) {
				return await http.delete(this.url + routeId, routeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/proroute/export`,
			name: "导出工艺路线",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_route");
			}
		}
	},
	protask: {
		listProtask: {
			url: `${config.API_URL}/mes/pro/protask/list`,
			name: "查询生产任务列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listGanttTaskList: {
			url: `${config.API_URL}/mes/pro/protask/listGanttTaskList`,
			name: "查询生产任务列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getProtask: {
			url: `${config.API_URL}/mes/pro/protask/`,
			name: "查询生产任务详细",
			get: async function (taskId) {
				return await http.get(this.url + taskId);
			}
		},
		addProtask: {
			url: `${config.API_URL}/mes/pro/protask`,
			name: "新增生产任务",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProtask: {
			url: `${config.API_URL}/mes/pro/protask`,
			name: "修改生产任务",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProtask: {
			url: `${config.API_URL}/mes/pro/protask/`,
			name: "删除生产任务",
			delete: async function (taskId) {
				return await http.delete(this.url + taskId, taskId);
			}
		},
		exportFile: {
			url: `${config.API_URL}mes/pro/protask/export`,
			name: "导出",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "protask");
			}
		},
		autoSchedule: {
			url: `${config.API_URL}/mes/pro/protask/autoSchedule`,
			name: "自动排产",
			post: async function (query) {
				return await http.post(this.url, query);
			}
		},
		startTask: {
			url: `${config.API_URL}/mes/pro/protask/startTask`,
			name: "任务开工",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		finishTask: {
			url: `${config.API_URL}/mes/pro/protask/finishTask/`,
			name: "完成任务",
			put: async function (taskId) {
				return await http.put(this.url + taskId);
			}
		},
		pauseTask: {
			url: `${config.API_URL}/mes/pro/protask/pauseTask`,
			name: "暂停任务",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		closeTask: {
			url: `${config.API_URL}/mes/pro/protask/closeTask`,
			name: "关闭任务",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		resumeTask: {
			url: `${config.API_URL}/mes/pro/protask/resumeTask/`,
			name: "恢复任务",
			put: async function (taskId) {
				return await http.put(this.url + taskId);
			}
		},
		restartTask: {
			url: `${config.API_URL}/mes/pro/protask/restartTask/`,
			name: "重启任务",
			put: async function (taskId) {
				return await http.put(this.url + taskId);
			}
		},
		whetherScheduleTask: {
			url: `${config.API_URL}/mes/pro/protask/whetherScheduleTask`,
			name: "手动排产前自动排任务",
			post: async function (query) {
				return await http.post(this.url, query);
			}
		},

		getProcessTaskData: {
			url: `${config.API_URL}/mes/pro/protask/getProcessTaskData`,
			name: "产量负荷排程 查询工序任务数量 时长等信息",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},

		changeWorkstation: {
			url: `${config.API_URL}/mes/pro/protask/changeWorkstation`,
			name: "生产任务变更站点",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		}
	},
	routeprocess: {
		listRouteprocess: {
			url: `${config.API_URL}/mes/pro/routeprocess/list`,
			name: "查询工艺组成列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listRoutesprocess: {
			url: `${config.API_URL}/mes/pro/routeprocess/listRoutes`,
			name: "查询工艺组成中对应工艺路线名称",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		listProductprocess: {
			url: `${config.API_URL}/mes/pro/routeprocess/listProductProcess/`,
			name: "查询产品的工艺组成列表",
			get: async function (productId) {
				return await http.get(this.url + productId);
			}
		},
		getRouteprocess: {
			url: `${config.API_URL}/mes/pro/routeprocess/`,
			name: "查询工艺组成详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addRouteprocess: {
			url: `${config.API_URL}/mes/pro/routeprocess`,
			name: "新增工艺组成",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRouteprocess: {
			url: `${config.API_URL}/mes/pro/routeprocess`,
			name: "修改工艺组成",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRouteprocess: {
			url: `${config.API_URL}/mes/pro/routeprocess/`,
			name: "删除工艺组成",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId, recordId);
			}
		},
		listBomProcess: {
			url: `${config.API_URL}/mes/pro/routeprocess/listBomProcess/`,
			name: "查询BOM的工艺组成列表",
			get: async function (bomId) {
				return await http.get(this.url + bomId);
			}
		},
	},
	routeproduct: {
		listRouteproduct: {
			url: `${config.API_URL}/mes/pro/routeproduct/list`,
			name: "查询产品制程列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getDept: {
			url: `${config.API_URL}/mes/pro/routeproduct/`,
			name: "查询产品制程详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addRouteproduct: {
			url: `${config.API_URL}/mes/pro/routeproduct`,
			name: "新增产品制程",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRouteproduct: {
			url: `${config.API_URL}/mes/pro/routeproduct`,
			name: "修改产品制程",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		moveRouteproduct: {
			url: `${config.API_URL}/mes/pro/routeproduct/move`,
			name: "修改产品制程",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRouteproduct: {
			url: `${config.API_URL}/mes/pro/routeproduct/`,
			name: "删除产品制程",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId, recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/routeproduct/export`,
			name: "导出产品制程",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "route_product");
			}
		}
	},
	routeproductbom: {
		listRouteproductbom: {
			url: `${config.API_URL}/mes/pro/routeproductbom/list`,
			name: "查询产品制程物料BOM列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getRouteproductbom: {
			url: `${config.API_URL}/mes/pro/routeproductbom/`,
			name: "查询产品制程物料BOM详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addRouteproductbom: {
			url: `${config.API_URL}/mes/pro/routeproductbom`,
			name: "新增产品制程物料BOM",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRouteproductbom: {
			url: `${config.API_URL}/mes/pro/routeproductbom`,
			name: "修改产品制程物料BOM",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRouteproductbom: {
			url: `${config.API_URL}/mes/pro/routeproductbom/`,
			name: "删除产品制程物料BOM",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId, recordId);
			}
		},
	},
	workorder: {
		listWorkorder: {
			url: `${config.API_URL}/mes/pro/workorder/list`,
			name: "查询生产工单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		inlistWorkorder: {
			url: `${config.API_URL}/mes/pro/workorder/listPro`,
			name: "查询入库生产工单列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getDept: {
			url: `${config.API_URL}/mes/pro/workorder/`,
			name: "查询生产工单详细",
			get: async function (workorderId) {
				return await http.get(this.url + workorderId);
			}
		},
		addWorkorder: {
			url: `${config.API_URL}/mes/pro/workorder`,
			name: "新增生产工单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWorkorder: {
			url: `${config.API_URL}/mes/pro/workorder`,
			name: "修改生产工单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWorkorder: {
			url: `${config.API_URL}/mes/pro/workorder/`,
			name: "删除生产工单",
			delete: async function (workorderId) {
				return await http.delete(this.url + workorderId, workorderId);
			}
		},
		listItems: {
			url: `${config.API_URL}/mes/pro/workorder/listItems`,
			name: "查询物料依赖列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getWorkorder:{
			url: `${config.API_URL}/mes/pro/workorder/`,
			name: "查询生产工单详细",
			get: async function (workorderId) {
				return await http.get(this.url + workorderId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/workorder/export`,
			name: "导出生产工单",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "work_order");
			}
		},
		revocation: {
			url: `${config.API_URL}/mes/pro/workorder/revocation/`,
			name: "撤回生产工单",
			put: async function (workorderId) {
				return await http.put(this.url + workorderId, workorderId);
			}
		},
		finishWorkOrderTip: {
			url: `${config.API_URL}/mes/pro/workorder/finishWorkOrderTip/`,
			name: "完成生产工单提示",
			get: async function (workorderId) {
				return await http.get(this.url + workorderId);
			}
		},
		finishWorkOrder: {
			url: `${config.API_URL}/mes/pro/workorder/finishWorkOrder/`,
			name: "完成生产工单",
			put: async function (workorderId) {
				return await http.put(this.url + workorderId);
			}
		},
		pauseWorkOrder: {
			url: `${config.API_URL}/mes/pro/workorder/pauseWorkOrder`,
			name: "暂停生产工单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		closeWorkOrder: {
			url: `${config.API_URL}/mes/pro/workorder/closeWorkOrder`,
			name: "关闭生产工单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		resumeWorkOrder: {
			url: `${config.API_URL}/mes/pro/workorder/resumeWorkOrder/`,
			name: "继续生产工单",
			put: async function (workorderId) {
				return await http.put(this.url + workorderId);
			}
		},
		restartWorkOrder: {
			url: `${config.API_URL}/mes/pro/workorder/restartWorkOrder/`,
			name: "重启生产工单",
			put: async function (workorderId) {
				return await http.put(this.url + workorderId);
			}
		},
		getWorkOrderProgress:{
			url: `${config.API_URL}/mes/pro/workorder/getWorkOrderProgress`,
			name: "查询生产工单进度",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
	},
	workorderbom: {
		listWorkorderbom: {
			url: `${config.API_URL}/mes/pro/workorderbom/list`,
			name: "查询生产工单BOM组成列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getWorkorderbom: {
			url: `${config.API_URL}/mes/pro/workorderbom/`,
			name: "查询生产工单BOM组成详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addWorkorderbom: {
			url: `${config.API_URL}/mes/pro/workorderbom`,
			name: "新增生产工单BOM组成",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWorkorderbom: {
			url: `${config.API_URL}/mes/pro/workorderbom`,
			name: "修改生产工单BOM组成",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWorkorderbom: {
			url: `${config.API_URL}/mes/pro/workorderbom/`,
			name: "删除生产工单BOM组成",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId, lineId);
			}
		},
		exportWorkOrderBom: {
			url: `${config.API_URL}/mes/pro/workorderbom/export`,
			name: "导出生产工单BOM组成",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "work_order_bom");
			}
		}
	},
	line: {
		listLine: {
			url: `${config.API_URL}/mes/pro/line/list`,
			name: "查询生产线列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getLine: {
			url: `${config.API_URL}/mes/pro/line/`,
			name: "查询生产线详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addLine: {
			url: `${config.API_URL}/mes/pro/line`,
			name: "新增生产线",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateLine: {
			url: `${config.API_URL}/mes/pro/line`,
			name: "修改生产线",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delLine: {
			url: `${config.API_URL}/mes/pro/line/`,
			name: "删除生产线",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId, lineId);
			}
		},
		exportLine: {
			url: `${config.API_URL}/mes/pro/line/export`,
			name: "导出生产线",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_line");
			}
		},
		listAll: {
			url: `${config.API_URL}/mes/pro/line/listAll`,
			name: "查询生产线列表",
			get: async function () {
				return await http.get(this.url);
			}
		}
	},
	proSetting: {
		listSetting: {
			url: `${config.API_URL}/mes/pro/proSetting/list`,
			name: "查询生产配置",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		updateSetting: {
			url: `${config.API_URL}/mes/pro/proSetting`,
			name: "修改生产配置",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		exportSetting: {
			url: `${config.API_URL}/mes/pro/proSetting/export`,
			name: "导出生产配置",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_setting");
			}
		},
		getSettingByKey: {
			url: `${config.API_URL}/mes/pro/proSetting/getSettingByKey/`,
			name: "查询生产配置",
			get: async function (settingKey) {
				return await http.get(this.url + settingKey);
			}
		},
	},
	plan: {
		listPlan: {
			url: `${config.API_URL}/mes/pro/plan/list`,
			name: "查询生产计划列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getPlan: {
			url: `${config.API_URL}/mes/pro/plan/`,
			name: "查询生产计划详细",
			get: async function (planId) {
				return await http.get(this.url + planId);
			}
		},
		addPlan: {
			url: `${config.API_URL}/mes/pro/plan`,
			name: "新增生产计划",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updatePlan: {
			url: `${config.API_URL}/mes/pro/plan`,
			name: "修改生产计划",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delPlan: {
			url: `${config.API_URL}/mes/pro/plan/`,
			name: "删除生产计划",
			delete: async function (planId) {
				return await http.delete(this.url + planId, planId);
			}
		},
		exportPlan: {
			url: `${config.API_URL}/mes/pro/plan/export`,
			name: "导出生产计划",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_plan");
			}
		},
		getLatestBom: {
			url: `${config.API_URL}/mes/pro/plan/getLatestBom/`,
			name: "查询最新bom",
			get: async function (productId) {
				return await http.get(this.url + productId);
			}
		},
		getBomTreeByBomId: {
			url: `${config.API_URL}/mes/pro/plan/getBomTreeByBomId/`,
			name: "根据bomId查询bom结构",
			get: async function (bomId) {
				return await http.get(this.url + bomId);
			}
		},
		exportBomTree: {
			url: `${config.API_URL}/mes/pro/plan/exportBomTree`,
			name: "导出BOM结构",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "plan_bom_tree");
			}
		},
		getUseMaterialList: {
			url: `${config.API_URL}/mes/pro/plan/getUseMaterialList`,
			name: "根据bomId查询用料明细",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getXYJUseMaterialList: {
			url: `${config.API_URL}/mes/pro/plan/getXYJUseMaterialList`,
			name: "根据saleOrderId查询用料明细",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportUseMaterial: {
			url: `${config.API_URL}/mes/pro/plan/exportUseMaterial`,
			name: "导出用料明细",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "material_detail");
			}
		},
		exportXYJUseMaterial: {
			url: `${config.API_URL}/mes/pro/plan/exportXYJUseMaterial`,
			name: "导出用料明细",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "material_detail");
			}
		},
		decomposePlan: {
			url: `${config.API_URL}/mes/pro/plan/decompose/`,
			name: "分解生产计划",
			put: async function (planId) {
				return await http.put(this.url + planId);
			}
		},
		decomposeAndSchedule: {
			url: `${config.API_URL}/mes/pro/plan/decomposeAndSchedule`,
			name: "分解生产计划并排产",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		revocation: {
			url: `${config.API_URL}/mes/pro/plan/revocation/`,
			name: "撤回生产计划",
			put: async function (planId) {
				return await http.put(this.url + planId);
			}
		},
		closePlan: {
			url: `${config.API_URL}/mes/pro/plan/closePlan`,
			name: "关闭生产计划",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		}
	},
	taskAppoint: {
		getAppoint: {
			url: `${config.API_URL}/mes/pro/task/appoint/`,
			name: "查询任务派工详细",
			get: async function (appointId) {
				return await http.get(this.url + appointId);
			}
		},
		getByTaskId: {
			url: `${config.API_URL}/mes/pro/task/appoint/getByTaskId/`,
			name: "查询任务派工详细",
			get: async function (taskId) {
				return await http.get(this.url + taskId);
			}
		},
		addAppoint: {
			url: `${config.API_URL}/mes/pro/task/appoint`,
			name: "新增任务派工",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateAppoint: {
			url: `${config.API_URL}/mes/pro/task/appoint`,
			name: "修改任务派工",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
	},
	taskReportItem: {
		getReportItem: {
			url: `${config.API_URL}/mes/pro/reportItem/getReportItem/`,
			name: "报工时获取检测项目接口",
			get: async function (taskId) {
				return await http.get(this.url + taskId);
			}
		},
		getInspectUserAndDept: {
			url: `${config.API_URL}/mes/pro/reportItem/getInspectUserAndDept`,
			name: "报工人获取检验人",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		report: {
			url: `${config.API_URL}/mes/pro/reportItem/report`,
			name: "报工",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		getReportItemInfo: {
			url: `${config.API_URL}/mes/pro/reportItem/`,
			name: "获取生产报工记录子表详细信息",
			get: async function (reportItemId) {
				return await http.get(this.url + reportItemId);
			}
		},
		checkCanReport: {
			url: `${config.API_URL}/mes/pro/reportItem/checkCanReport`,
			name: "校验是否可以报工",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getUserReportStatistics: {
			url: `${config.API_URL}/mes/pro/reportItem/getUserReportStatistics`,
			name: "查询员工报工统计列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportReportStatistics: {
			url: `${config.API_URL}/mes/pro/reportItem/exportReportStatistics`,
			name: "导出员工报工统计",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, '员工报工统计');
			}
		},
	},
	repairRecord: {
		listRepairRecord: {
			url: `${config.API_URL}/mes/pro/repairRecord/list`,
			name: "查询返修记录列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getRepairRecord: {
			url: `${config.API_URL}/mes/pro/repairRecord/`,
			name: "查询返修记录详细",
			get: async function (repairId) {
				return await http.get(this.url + repairId);
			}
		},
		exportRepairRecord: {
			url: `${config.API_URL}/mes/pro/repairRecord/export`,
			name: "导出返修记录",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_repair_record");
			}
		},
	},
	executeProgress: {
		listExecuteProgress: {
			url: `${config.API_URL}/mes/pro/progress/list`,
			name: "查询任务执行进度列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		addPrintAgainReasonProgress: {
			url: `${config.API_URL}/mes/pro/progress/addPrintAgainReasonProgress`,
			name: "新增补打标签进度",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		}
	},
	cartonPrintRecord: {
		generateCardLabel: {
			url: `${config.API_URL}/mes/pro/carton/print/generate/`,
			name: "生成产品标识卡",
			get: async function (workorderId) {
				return await http.get(this.url + workorderId);
			}
		},
	},
	subsidiary: {
		listProcessSubsidiary: {
			url: `${config.API_URL}/mes/pro/process/subsidiary/list`,
			name: "查询工序-辅助作业列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},

	},
	proPartWO: {
		listPartWORecord: {
			url: `${config.API_URL}/mes/pro/partWO/list`,
			name: "查询分随工单记录列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		exportPartWORecord: {
			url: `${config.API_URL}/mes/pro/partWO/export`,
			name: "导出分随工单记录",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_part_workorder_record");
			}
		},
		selectByRecordId: {
			url: `${config.API_URL}/mes/pro/partWO/`,
			name: "查询分随工单记录详情",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		getPartQuantity: {
			url: `${config.API_URL}/mes/pro/partWO/getPartQuantity/`,
			name: "查询可拆分数量",
			get: async function (taskId) {
				return await http.get(this.url + taskId);
			}
		},
		getPartBatchCode: {
			url: `${config.API_URL}/mes/pro/partWO/getPartBatchCode`,
			name: "查询默认分拆工单产品批号",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		getProductTypeId: {
			url: `${config.API_URL}/mes/pro/partWO/getProductTypeId/`,
			name: "查询产品分类id",
			get: async function (productId) {
				return await http.get(this.url + productId);
			}
		},
		getBomListByProductId: {
			url: `${config.API_URL}/mes/pro/partWO/getBomListByProductId/`,
			name: "根据产品id查询bom列表",
			get: async function (productId) {
				return await http.get(this.url + productId);
			}
		},
		getRouteProcessByBomId: {
			url: `${config.API_URL}/mes/pro/partWO/getRouteProcessByBomId/`,
			name: "根据bomid查询工序组成",
			get: async function (bomId) {
				return await http.get(this.url + bomId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/pro/partWO`,
			name: "分工单-执行拆分",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		checkLastTaskFinished: {
			url: `${config.API_URL}/mes/pro/partWO/checkLastTaskFinished/`,
			name: "查询上道工序是否完工",
			get: async function (taskId) {
				return await http.get(this.url + taskId);
			}
		},
	},
	partTask: {
		getPartQuantity: {
			url: `${config.API_URL}/mes/pro/partTask/getPartQuantity/`,
			name: "查询可拆分数量",
			get: async function (taskId) {
				return await http.get(this.url + taskId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/pro/partTask/execute`,
			name: "分任务-执行拆分",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		}
	},
	mrp: {
		listMrp: {
			url: `${config.API_URL}/mes/pro/mrp/list`,
			name: "查询MRP运算记录列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		buildMrpProduct: {
			url: `${config.API_URL}/mes/pro/mrp/buildMrpProduct`,
			name: "根据销售订单或生产计划，组装MRP产品信息",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		getMrp: {
			url: `${config.API_URL}/mes/pro/mrp/`,
			name: "查询MRP详情",
			get: async function (mrpId) {
				return await http.get(this.url + mrpId);
			}
		},
		addMRP: {
			url: `${config.API_URL}/mes/pro/mrp`,
			name: "新增MRP",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateMRP: {
			url: `${config.API_URL}/mes/pro/mrp`,
			name: "修改MRP",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delMRP: {
			url: `${config.API_URL}/mes/pro/mrp/`,
			name: "删除MRP",
			delete: async function (mrpIds) {
				return await http.delete(this.url + mrpIds, mrpIds);
			}
		},
		getMrpOperationRecord: {
			url: `${config.API_URL}/mes/pro/mrp/getMrpOperationRecord/`,
			name: "查询MRP操作记录",
			get: async function (mrpId) {
				return await http.get(this.url + mrpId);
			}
		},
	},
	mrpAssistSuggestion: {
		listAssistSuggestion: {
			url: `${config.API_URL}/mes/pro/mrp/assistSuggestion/list`,
			name: "查询MRP外协生产建议列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		assistApply: {
			url: `${config.API_URL}/mes/pro/mrp/assistSuggestion/assistApply/`,
			name: "外协建议请购",
			get: async function (ids) {
				return await http.get(this.url + ids);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/mrp/assistSuggestion/export`,
			name: "导出外协建议请购",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_mrp_assist_suggestion");
			}
		},

	},
	mrpPurchaseSuggestion: {
		listPurchaseSuggestion: {
			url: `${config.API_URL}/mes/pro/mrp/purchaseSuggestion/list`,
			name: "查询MRP外购请购建议列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		purchaseApply: {
			url: `${config.API_URL}/mes/pro/mrp/purchaseSuggestion/purchaseApply/`,
			name: "外购建议请购",
			get: async function (ids) {
				return await http.get(this.url + ids);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/pro/mrp/purchaseSuggestion/export`,
			name: "导出外购建议请购",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "pro_mrp_purchase_suggestion");
			}
		},
		getPurchaseSuggestionList: {
			url: `${config.API_URL}/mes/pro/mrp/purchaseSuggestion/getPurchaseSuggestionList/`,
			name: "查询外购建议请购",
			get: async function (ids) {
				return await http.get(this.url + ids);
			}
		},
	},
	mrpProductionSuggestion: {
		listProductionSuggestion: {
			url: `${config.API_URL}/mes/pro/mrp/productionSuggestion/list`,
			name: "查询MRP厂内生产建议列表",
			get: async function (query) {
				return await http.get(this.url, query);
			}
		},
		decompose: {
			url: `${config.API_URL}/mes/pro/mrp/productionSuggestion/decompose`,
			name: "分解",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		decomposeAndSchedule: {
			url: `${config.API_URL}/mes/pro/mrp/productionSuggestion/decomposeAndSchedule`,
			name: "分解并排产",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		}
	}
}
