import config from "@/config"
import http from "@/utils/request"

export default {
	area: {
		listArea: {
			url: `${config.API_URL}/mes/wm/area/list`,
			name: "查询库位列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getArea: {
			url: `${config.API_URL}/mes/wm/area/`,
			name: "查询库位详细",
			get: async function (areaId) {
				return await http.get(this.url + areaId);
			}
		},
		addArea: {
			url: `${config.API_URL}/mes/wm/area`,
			name: "新增库位",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateArea: {
			url: `${config.API_URL}/mes/wm/area`,
			name: "修改库位",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delArea: {
			url: `${config.API_URL}/mes/wm/area/`,
			name: "删除库位",
			delete: async function (areaId) {
				return await http.delete(this.url + areaId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/area/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "area");
			}
		},
		listAreaWL: {
			url: `${config.API_URL}/mes/wm/area/listArea`,
			name: "查询有仓库有库位有库区的列表集合",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
	},
	barcode: {
		listBarcode: {
			url: `${config.API_URL}/mes/wm/barcode/list`,
			name: "查询条码清单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getBarcode: {
			url: `${config.API_URL}/mes/wm/barcode/`,
			name: "查询条码清单详细",
			get: async function (barcodeId) {
				return await http.get(this.url + barcodeId);
			}
		},
		addBarcode: {
			url: `${config.API_URL}/mes/wm/barcode`,
			name: "新增条码清单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateBarcode: {
			url: `${config.API_URL}/mes/wm/barcode`,
			name: "修改条码清单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delBarcode: {
			url: `${config.API_URL}/mes/wm/barcode/`,
			name: "删除条码清单",
			delete: async function (barcodeId) {
				return await http.delete(this.url + barcodeId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/barcode/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "barcode");
			}
		}
	},
	barcodeconfig: {
		listBarcodeconfig: {
			url: `${config.API_URL}/mes/wm/barcodeconfig/list`,
			name: "查询条码配置列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getBarcodeconfig: {
			url: `${config.API_URL}/mes/wm/barcodeconfig/`,
			name: "查询条码配置详细",
			get: async function (configId) {
				return await http.get(this.url + configId);
			}
		},
		addBarcodeconfig: {
			url: `${config.API_URL}/mes/wm/barcodeconfig`,
			name: "新增条码配置",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateBarcodeconfig: {
			url: `${config.API_URL}/mes/wm/barcodeconfig`,
			name: "修改条码配置",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delBarcodeconfig: {
			url: `${config.API_URL}/mes/wm/barcodeconfig/`,
			name: "删除条码配置",
			delete: async function (configId) {
				return await http.delete(this.url + configId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/barcodeconfig/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "barcodeconfig");
			}
		}
	},
	issueheader: {
		listIssueheader: {
			url: `${config.API_URL}/mes/wm/issueheader/list`,
			name: "查询生产领料单头列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getIssueheader: {
			url: `${config.API_URL}/mes/wm/issueheader/`,
			name: "查询生产领料单头详细",
			get: async function (issueId) {
				return await http.get(this.url + issueId);
			}
		},
		addIssueheader: {
			url: `${config.API_URL}/mes/wm/issueheader`,
			name: "新增生产领料单头",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateIssueheader: {
			url: `${config.API_URL}/mes/wm/issueheader`,
			name: "修改生产领料单头",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delIssueheader: {
			url: `${config.API_URL}/mes/wm/issueheader/`,
			name: "删除生产领料单头",
			delete: async function (issueId) {
				return await http.delete(this.url + issueId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/wm/issueheader/`,
			name: "执行出库",
			put: async function (issueId) {
				return await http.put(this.url + issueId);
			}
		},
		batchExecute: {
			url: `${config.API_URL}/mes/wm/issueheader/batchExecute`,
			name: "批量执行出库",
			post: async function (issueId) {
				return await http.post(this.url ,issueId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/issueheader/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "issueheader");
			}
		},
		outWarehouseList: {
			url: `${config.API_URL}/mes/wm/wmstock/outWarehouseList`,
			name: "出库记录",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getOrderCode: {
			url: `${config.API_URL}/mes/wm/issueheader/getOrderCode/`,
			name: "查询订单编号",
			get: async function (taskId) {
				return await http.get(this.url+taskId);
			}
		}
	},
	issueline: {
		listBatch: {
			url: `${config.API_URL}/mes/wm/issueline/listBatch`,
			name: "查询批量生成领料单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		listIssueline: {
			url: `${config.API_URL}/mes/wm/issueline/list`,
			name: "查询生产领料单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getIssueline: {
			url: `${config.API_URL}/mes/wm/issueline/`,
			name: "查询生产领料单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addIssueline: {
			url: `${config.API_URL}/mes/wm/issueline`,
			name: "新增生产领料单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateIssueline: {
			url: `${config.API_URL}/mes/wm/issueline`,
			name: "修改生产领料单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delIssueline: {
			url: `${config.API_URL}/mes/wm/issueline/`,
			name: "删除生产领料单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
	},
	itemconsume: {
		listItemconsume: {
			url: `${config.API_URL}/mes/wm/itemconsume/list`,
			name: "查询物料消耗记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getItemconsume: {
			url: `${config.API_URL}/mes/wm/itemconsume/`,
			name: "查询物料消耗记录详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addItemconsume: {
			url: `${config.API_URL}/mes/wm/itemconsume`,
			name: "新增物料消耗记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateItemconsume: {
			url: `${config.API_URL}/mes/wm/itemconsume`,
			name: "修改物料消耗记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delItemconsume: {
			url: `${config.API_URL}/mes/wm/itemconsume/`,
			name: "删除物料消耗记录",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},
	},
	itemconsumeline: {
		listItemconsumeline: {
			url: `${config.API_URL}/mes/wm/itemconsumeline/list`,
			name: "查询物料消耗记录行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getItemconsumeline: {
			url: `${config.API_URL}/mes/wm/itemconsumeline/`,
			name: "查询物料消耗记录行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addItemconsumeline: {
			url: `${config.API_URL}/mes/wm/itemconsumeline`,
			name: "新增物料消耗记录行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateItemconsumeline: {
			url: `${config.API_URL}/mes/wm/itemconsumeline`,
			name: "修改物料消耗记录行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delItemconsumeline: {
			url: `${config.API_URL}/mes/wm/itemconsumeline/`,
			name: "删除物料消耗记录行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
	},
	itemrecpt: {
		listItemrecpt: {
			url: `${config.API_URL}/mes/wm/itemrecpt/list`,
			name: "查询物料入库单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listRecpt: {
			url: `${config.API_URL}/mes/wm/itemrecpt/listItemRecpt`,
			name: "查询物料入库单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listItem: {
			url: `${config.API_URL}/mes/wm/itemrecpt/listItem`,
			name: "查询物料入库单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getItemrecpt: {
			url: `${config.API_URL}/mes/wm/itemrecpt/`,
			name: "查询物料入库单详细",
			get: async function (recptId) {
				return await http.get(this.url + recptId);
			}
		},
		addItemrecpt: {
			url: `${config.API_URL}/mes/wm/itemrecpt`,
			name: "新增物料入库单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateItemrecpt: {
			url: `${config.API_URL}/mes/wm/itemrecpt`,
			name: "修改物料入库单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delItemrecpt: {
			url: `${config.API_URL}/mes/wm/itemrecpt/`,
			name: "删除物料入库单",
			delete: async function (recptId) {
				return await http.delete(this.url + recptId);
			}
		},
		confirmItemrecpt: {
			url: `${config.API_URL}/mes/wm/itemrecpt/confirm`,
			name: "确认物料入库单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		//执行入库
		execute: {
			url: `${config.API_URL}/mes/wm/itemrecpt/`,
			name: "执行入库",
			put: async function (recptId) {
				return await http.put(this.url + recptId);
			}
		},
		exportFile: {
			url: `${config.API_URL}mes/wm/itemrecpt/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "itemrecpt");
			}
		}
	},
	itemrecptline: {
		listItemrecptline: {
			url: `${config.API_URL}/mes/wm/itemrecptline/list`,
			name: "查询物料入库单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getItemrecptline: {
			url: `${config.API_URL}/mes/wm/itemrecptline/`,
			name: "查询物料入库单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addItemrecptline: {
			url: `${config.API_URL}/mes/wm/itemrecptline`,
			name: "查询物料入库单行详细",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateItemrecptline: {
			url: `${config.API_URL}/mes/wm/itemrecptline`,
			name: "新增物料入库单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delItemrecptline: {
			url: `${config.API_URL}/mes/wm/itemrecptline/`,
			name: "删除物料入库单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
	},
	location: {
		listLocation: {
			url: `${config.API_URL}/mes/wm/location/list`,
			name: "查询库区设置列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getLocation: {
			url: `${config.API_URL}/mes/wm/location/`,
			name: "查询库区设置详细",
			get: async function (locationId) {
				return await http.get(this.url + locationId);
			}
		},
		addLocation: {
			url: `${config.API_URL}/mes/wm/location`,
			name: "新增库区设置",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateLocation: {
			url: `${config.API_URL}/mes/wm/location`,
			name: "修改库区设置",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delLocation: {
			url: `${config.API_URL}/mes/wm/location/`,
			name: "删除库区设置",
			delete: async function (locationId) {
				return await http.delete(this.url + locationId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/location/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "location");
			}
		}
	},
	package: {
		listPackage: {
			url: `${config.API_URL}/mes/wm/package/list`,
			name: "查询装箱单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getPackage: {
			url: `${config.API_URL}/mes/wm/package/`,
			name: "查询装箱单详细",
			get: async function (packageId) {
				return await http.get(this.url + packageId);
			}
		},
		addPackage: {
			url: `${config.API_URL}/mes/wm/package`,
			name: "新增装箱单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		addSubPackage: {
			url: `${config.API_URL}/mes/wm/package/addsub`,
			name: "添加子箱",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		updatePackage: {
			url: `${config.API_URL}/mes/wm/package`,
			name: "修改装箱单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delPackage: {
			url: `${config.API_URL}/mes/wm/package/`,
			name: "删除装箱单",
			delete: async function (packageId) {
				return await http.delete(this.url + packageId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/package/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "package");
			}
		}
	},
	packageline: {
		listPackageline: {
			url: `${config.API_URL}/mes/wm/packageline/list`,
			name: "查询装箱明细列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getPackageline: {
			url: `${config.API_URL}/mes/wm/packageline/`,
			name: "查询装箱明细详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addPackageline: {
			url: `${config.API_URL}/mes/wm/packageline`,
			name: "新增装箱明细",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updatePackageline: {
			url: `${config.API_URL}/mes/wm/packageline`,
			name: "修改装箱明细",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delPackageline: {
			url: `${config.API_URL}/mes/wm/packageline/`,
			name: "删除装箱明细",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/packageline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "packageline");
			}
		}
	},
	productproduce: {
		listProductproduce: {
			url: `${config.API_URL}/mes/wm/productproduce/list`,
			name: "查询产品产出记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getProductproduce: {
			url: `${config.API_URL}/mes/wm/productproduce/`,
			name: "查询产品产出记录详细",
			get: async function (recordId) {
				return await http.get(this.url + recordId);
			}
		},
		addProductproduce: {
			url: `${config.API_URL}/mes/wm/productproduce`,
			name: "新增产品产出记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProductproduce: {
			url: `${config.API_URL}/mes/wm/productproduce`,
			name: "修改产品产出记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProductproduce: {
			url: `${config.API_URL}/mes/wm/productproduce/`,
			name: "删除产品产出记录",
			delete: async function (recordId) {
				return await http.delete(this.url + recordId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/productproduce/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "productproduce");
			}
		}
	},
	productproduceline: {
		listProductproduceline: {
			url: `${config.API_URL}/mes/wm/productproduceline/list`,
			name: "查询产品产出记录行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getProductproduceline: {
			url: `${config.API_URL}/mes/wm/productproduceline/`,
			name: "查询产品产出记录行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addProductproduceline: {
			url: `${config.API_URL}/mes/wm/productproduceline`,
			name: "新增产品产出记录行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProductproduceline: {
			url: `${config.API_URL}/mes/wm/productproduceline`,
			name: "修改产品产出记录行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProductproduceline: {
			url: `${config.API_URL}/mes/wm/productproduceline/`,
			name: "删除产品产出记录行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/productproduceline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "productproduceline");
			}
		}
	},
	productrecpt: {
		listProductrecpt: {
			url: `${config.API_URL}/mes/wm/productrecpt/list`,
			name: "查询产品入库录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getProductrecpt: {
			url: `${config.API_URL}/mes/wm/productrecpt/`,
			name: "查询产品入库录详细",
			get: async function (recptId) {
				return await http.get(this.url + recptId);
			}
		},
		addProductrecpt: {
			url: `${config.API_URL}/mes/wm/productrecpt`,
			name: "新增产品入库录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProductrecpt: {
			url: `${config.API_URL}/mes/wm/productrecpt`,
			name: "修改产品入库录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProductrecpt: {
			url: `${config.API_URL}/mes/wm/productrecpt/`,
			name: "删除产品入库录",
			delete: async function (recptId) {
				return await http.delete(this.url + recptId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/wm/productrecpt/`,
			name: "执行入库",
			put: async function (recptId) {
				return await http.put(this.url + recptId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/productrecpt/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "productrecpt");
			}
		},
		listProductrecptBatch: {
			url: `${config.API_URL}/mes/wm/productrecptBatch/list`,
			name: "批量查询产品入库录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getProductrecptBatch: {
			url: `${config.API_URL}/mes/wm/productrecptBatch/`,
			name: "批量查询产品入库录详细",
			get: async function (recptId) {
				return await http.get(this.url + recptId);
			}
		},
		executeBatch: {
			url: `${config.API_URL}/mes/wm/productrecptBatch/`,
			name: "批量执行入库",
			put: async function (recptId) {
				return await http.put(this.url + recptId);
			}
		},
		addProductrecptBatch: {
			url: `${config.API_URL}/mes/wm/productrecptBatch`,
			name: "批量新增产品入库录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProductrecptBatch: {
			url: `${config.API_URL}/mes/wm/productrecptBatch`,
			name: "批量修改产品入库录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		exportFileBatch: {
			url: `${config.API_URL}/mes/wm/productrecptBatch/export`,
			name: "批量导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "productrecptBatch");
			}
		}
	},
	productrecptline: {
		listProductrecptline: {
			url: `${config.API_URL}/mes/wm/productrecptline/list`,
			name: "查询产品入库记录行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getProductrecptline: {
			url: `${config.API_URL}/mes/wm/productrecptline/`,
			name: "查询产品入库记录行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addProductrecptline: {
			url: `${config.API_URL}/mes/wm/productrecptline`,
			name: "新增产品入库记录行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProductrecptline: {
			url: `${config.API_URL}/mes/wm/productrecptline`,
			name: "修改产品入库记录行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProductrecptline: {
			url: `${config.API_URL}/mes/wm/productrecptline/`,
			name: "删除产品入库记录行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/productrecptline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "productrecptline");
			}
		}
	},
	productsalse: {
		listProductsalse: {
			url: `${config.API_URL}/mes/wm/productsalse/list`,
			name: "查询销售出库单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listSalse: {
			url: `${config.API_URL}/mes/wm/productsalse/listSalse`,
			name: "查询销售出库单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getProductsalse: {
			url: `${config.API_URL}/mes/wm/productsalse/`,
			name: "查询销售出库单详细",
			get: async function (salseId) {
				return await http.get(this.url + salseId);
			}
		},
		addProductsalse: {
			url: `${config.API_URL}/mes/wm/productsalse`,
			name: "新增销售出库单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProductsalse: {
			url: `${config.API_URL}/mes/wm/productsalse`,
			name: "修改销售出库单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProductsalse: {
			url: `${config.API_URL}/mes/wm/productsalse/`,
			name: "删除销售出库单",
			delete: async function (salseId) {
				return await http.delete(this.url + salseId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/wm/productsalse/execute`,
			name: "执行出库",
			post: async function (data) {
				return await http.post(this.url , data);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/productsalse/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "productsalse");
			}
		},
		listSalseH: {
			url: `${config.API_URL}/mes/wm/productsalse/listSalseH`,
			name: "查询销售出库单表头列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getSaleOrderCode: {
			url: `${config.API_URL}/mes/wm/productsalse/getSaleOrderCode`,
			name: "查询销售出库单表头列表",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		addSavePackingCache: {
			url: `${config.API_URL}/mes/wm/productsalse/addSavePackingCache`,
			name: "保存缓存包标签码信息",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		getPackingCache: {
			url: `${config.API_URL}/mes/wm/productsalse/getPackingCache`,
			name: "获取缓存包标签码",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		delPackingCache: {
			url: `${config.API_URL}/mes/wm/productsalse/delPackingCache`,
			name: "删除缓存中的数据",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		getupdateByOrder: {
			url: `${config.API_URL}/mes/wm/productsalse/getupdateByOrder`,
			name: "只更新销售出库单主表显示排序字段",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
	},
	productsalseline: {
		listProductsalseline: {
			url: `${config.API_URL}/mes/wm/productsalseline/list`,
			name: "查询产品销售出库行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getProductsalseline: {
			url: `${config.API_URL}/mes/wm/productsalseline/`,
			name: "查询产品销售出库行详细",
			get: async function (salseId) {
				return await http.get(this.url + salseId);
			}
		},
		addProductsalseline: {
			url: `${config.API_URL}/mes/wm/productsalseline`,
			name: "新增产品销售出库行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateProductsalseline: {
			url: `${config.API_URL}/mes/wm/productsalseline`,
			name: "修改产品销售出库行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delProductsalseline: {
			url: `${config.API_URL}/mes/wm/productsalseline/`,
			name: "删除产品销售出库行",
			delete: async function (salseId) {
				return await http.delete(this.url + salseId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/productsalseline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "productsalse");
			}
		},
		getOrder: {
			url: `${config.API_URL}/mes/wm/productsalseline/getOrder/`,
			name: "根据销售订单id获取销售出库行信息",
			get: async function (orderId) {
				return await http.get(this.url + orderId);
			}
		},
		getOrderLine: {
			url: `${config.API_URL}/mes/wm/productsalseline/getOrderLine`,
			name: "获取订单销售出库数据",
			post: async function (data) {
				return await http.post(this.url , data);
			}
		}
	},
	rtissue: {
		listRtissue: {
			url: `${config.API_URL}/mes/wm/rtissue/list`,
			name: "查询生产退料单头列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getRtissue: {
			url: `${config.API_URL}/mes/wm/rtissue/`,
			name: "查询生产退料单头详细",
			get: async function (rtId) {
				return await http.get(this.url + rtId);
			}
		},
		addRtissue: {
			url: `${config.API_URL}/mes/wm/rtissue`,
			name: "新增生产退料单头",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRtissue: {
			url: `${config.API_URL}/mes/wm/rtissue`,
			name: "修改生产退料单头",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRtissue: {
			url: `${config.API_URL}/mes/wm/rtissue/`,
			name: "删除生产退料单头",
			delete: async function (rtId) {
				return await http.delete(this.url + rtId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/wm/rtissue/`,
			name: "执行退料",
			put: async function (rtId) {
				return await http.put(this.url + rtId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/rtissue/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "rtissue");
			}
		}
	},
	rtissueline: {
		listRtissueline: {
			url: `${config.API_URL}/mes/wm/rtissueline/list`,
			name: "查询生产退料单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getRtissueline: {
			url: `${config.API_URL}/mes/wm/rtissueline/`,
			name: "查询生产退料单行详细",
			get: async function (salseId) {
				return await http.get(this.url + salseId);
			}
		},
		addRtissueline: {
			url: `${config.API_URL}/mes/wm/rtissueline`,
			name: "新增生产退料单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRtissueline: {
			url: `${config.API_URL}/mes/wm/rtissueline`,
			name: "修改生产退料单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRtissueline: {
			url: `${config.API_URL}/mes/wm/rtissueline/`,
			name: "删除生产退料单行",
			delete: async function (salseId) {
				return await http.delete(this.url + salseId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/rtissueline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "rtissueline");
			}
		}
	},
	rtsalse: {
		listRtsalse: {
			url: `${config.API_URL}/mes/wm/rtsalse/list`,
			name: "查询产品销售退货单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getRtsalse: {
			url: `${config.API_URL}/mes/wm/rtsalse/`,
			name: "查询产品销售退货单详细",
			get: async function (rtId) {
				return await http.get(this.url + rtId);
			}
		},
		addRtsalse: {
			url: `${config.API_URL}/mes/wm/rtsalse`,
			name: "新增产品销售退货单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRtsalse: {
			url: `${config.API_URL}/mes/wm/rtsalse`,
			name: "修改产品销售退货单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRtsalse: {
			url: `${config.API_URL}/mes/wm/rtsalse/`,
			name: "删除产品销售退货单",
			delete: async function (rtId) {
				return await http.delete(this.url + rtId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/wm/rtsalse/`,
			name: "执行退货",
			put: async function (rtId) {
				return await http.put(this.url + rtId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/rtsalse/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "rtsalse");
			}
		}
	},
	rtsalseline: {
		listRtsalseline: {
			url: `${config.API_URL}/mes/wm/rtsalseline/list`,
			name: "查询产品销售退货行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getRtsalseline: {
			url: `${config.API_URL}/mes/wm/rtsalseline/`,
			name: "查询产品销售退货行详细",
			get: async function (salseId) {
				return await http.get(this.url + salseId);
			}
		},
		addRtsalseline: {
			url: `${config.API_URL}/mes/wm/rtsalseline`,
			name: "新增产品销售退货行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRtsalseline: {
			url: `${config.API_URL}/mes/wm/rtsalseline`,
			name: "修改产品销售退货行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRtsalseline: {
			url: `${config.API_URL}/mes/wm/rtsalseline/`,
			name: "删除产品销售退货行",
			delete: async function (salseId) {
				return await http.delete(this.url + salseId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/rtsalseline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "rtsalseline");
			}
		}
	},
	rtvendor: {
		listRtvendor: {
			url: `${config.API_URL}/mes/wm/rtvendor/list`,
			name: "查询供应商退货列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getRtvendor: {
			url: `${config.API_URL}/mes/wm/rtvendor/`,
			name: "查询供应商退货详细",
			get: async function (rtId) {
				return await http.get(this.url + rtId);
			}
		},
		addRtvendor: {
			url: `${config.API_URL}/mes/wm/rtvendor`,
			name: "新增供应商退货",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRtvendor: {
			url: `${config.API_URL}/mes/wm/rtvendor`,
			name: "修改供应商退货",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRtvendor: {
			url: `${config.API_URL}/mes/wm/rtvendor/`,
			name: "删除供应商退货",
			delete: async function (rtId) {
				return await http.delete(this.url + rtId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/wm/rtvendor/`,
			name: "执行入库",
			put: async function (rtId) {
				return await http.put(this.url + rtId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/rtvendor/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "rtsalse");
			}
		}
	},
	rtvendorline: {
		listRtvendorline: {
			url: `${config.API_URL}/mes/wm/rtvendorline/list`,
			name: "查询供应商退货行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getRtvendorline: {
			url: `${config.API_URL}/mes/wm/rtvendorline/`,
			name: "查询供应商退货行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addRtvendorline: {
			url: `${config.API_URL}/mes/wm/rtvendorline`,
			name: "新增供应商退货行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateRtvendorline: {
			url: `${config.API_URL}/mes/wm/rtvendorline`,
			name: "修改供应商退货行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delRtvendorline: {
			url: `${config.API_URL}/mes/wm/rtvendorline/`,
			name: "删除供应商退货行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/rtvendorline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "rtvendorline");
			}
		}
	},
	sn: {
		list: {
			url: `${config.API_URL}/mes/wm/sn/list`,
			name: "查询SN码列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listSn: {
			url: `${config.API_URL}/mes/wm/sn/listSn`,
			name: "查询SN码列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getSn: {
			url: `${config.API_URL}/mes/wm/sn/`,
			name: "查询SN码详细",
			get: async function (snId) {
				return await http.get(this.url + snId);
			}
		},
		addSn: {
			url: `${config.API_URL}/mes/wm/sn`,
			name: "新增SN码",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateSn: {
			url: `${config.API_URL}/mes/wm/sn`,
			name: "修改SN码",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delSn: {
			url: `${config.API_URL}/mes/wm/sn/`,
			name: "删除SN码",
			delete: async function (snId) {
				return await http.delete(this.url + snId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/sn/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "sn");
			}
		}
	},
	transfer: {
		listTransfer: {
			url: `${config.API_URL}/mes/wm/transfer/list`,
			name: "查询转移单列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getTransfer: {
			url: `${config.API_URL}/mes/wm/transfer/`,
			name: "查询转移单详细",
			get: async function (transferId) {
				return await http.get(this.url + transferId);
			}
		},
		addTransfer: {
			url: `${config.API_URL}/mes/wm/transfer`,
			name: "新增转移单",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateTransfer: {
			url: `${config.API_URL}/mes/wm/transfer`,
			name: "修改转移单",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delTransfer: {
			url: `${config.API_URL}/mes/wm/transfer/`,
			name: "删除转移单",
			delete: async function (transferId) {
				return await http.delete(this.url + transferId);
			}
		},
		execute: {
			url: `${config.API_URL}/mes/wm/transfer/`,
			name: "执行转移",
			put: async function (transferId) {
				return await http.put(this.url + transferId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/transfer/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "调拨记录");
			}
		}
	},
	transferline: {
		listTransferline: {
			url: `${config.API_URL}/mes/wm/transferline/list`,
			name: "查询转移单行列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getTransferline: {
			url: `${config.API_URL}/mes/wm/transferline/`,
			name: "查询转移单行详细",
			get: async function (lineId) {
				return await http.get(this.url + lineId);
			}
		},
		addTransferline: {
			url: `${config.API_URL}/mes/wm/transferline`,
			name: "新增转移单行",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateTransferline: {
			url: `${config.API_URL}/mes/wm/transferline`,
			name: "修改转移单行",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delTransferline: {
			url: `${config.API_URL}/mes/wm/transferline/`,
			name: "删除转移单行",
			delete: async function (lineId) {
				return await http.delete(this.url + lineId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/transferline/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "transferline");
			}
		}
	},
	warehouse: {
		listWarehouse: {
			url: `${config.API_URL}/mes/wm/warehouse/list`,
			name: "查询仓库设置列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getTreeList: {
			url: `${config.API_URL}/mes/wm/warehouse/getTreeList`,
			name: "查询仓库树形列表",
			get: async function () {
				return await http.get(this.url);
			}
		},
		getTreeListExe: {
			url: `${config.API_URL}/mes/wm/warehouse/getTreeListExe`,
			name: "查询仓库树形列表",
			post: async function (data) {
				return await http.get(this.url,data);
			}
		},
		getWarehouse: {
			url: `${config.API_URL}/mes/wm/warehouse/`,
			name: "查询仓库设置详细",
			get: async function (warehouseId) {
				return await http.get(this.url + warehouseId);
			}
		},
		addWarehouse: {
			url: `${config.API_URL}/mes/wm/warehouse`,
			name: "新增仓库设置",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWarehouse: {
			url: `${config.API_URL}/mes/wm/warehouse`,
			name: "修改仓库设置",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWarehouse: {
			url: `${config.API_URL}/mes/wm/warehouse/`,
			name: "删除仓库设置",
			delete: async function (warehouseId) {
				return await http.delete(this.url + warehouseId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/warehouse/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "warehouse");
			}
		}
	},
	wmstock: {
		listWmstock: {
			url: `${config.API_URL}/mes/wm/wmstock/list`,
			name: "查询库存记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		itemList: {
			url: `${config.API_URL}/mes/wm/wmstock/itemList`,
			name: "查询库存记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		issuelistWmstock: {
			url: `${config.API_URL}/mes/wm/wmstock/listIssue`,
			name: "查询领料库存记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		listIssueId: {
			url: `${config.API_URL}/mes/wm/wmstock/listIssueId`,
			name: "根据领料行id查询库存",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getWmstock: {
			url: `${config.API_URL}/mes/wm/wmstock/`,
			name: "查询库存记录详细",
			get: async function (materialStockId) {
				return await http.get(this.url + materialStockId);
			}
		},
		addWmstock: {
			url: `${config.API_URL}/mes/wm/wmstock`,
			name: "新增库存记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateWmstock: {
			url: `${config.API_URL}/mes/wm/wmstock`,
			name: "修改库存记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delWmstock: {
			url: `${config.API_URL}/mes/wm/wmstock/`,
			name: "删除库存记录",
			delete: async function (materialStockId) {
				return await http.delete(this.url + materialStockId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/wmstock/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "wmstock");
			}
		},
		exportFileWs: {
			url: `${config.API_URL}/mes/wm/wmstock/exportWs`,
			name: "导出库存附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "wmstock");
			}
		}
	},
	wmstockexe: {
		listWmstockExe: {
			url: `${config.API_URL}/mes/wm/issuelineexe/list`,
			name: "查询执行库存记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getIssuelineexe: {
			url: `${config.API_URL}/mes/wm/issuelineexe/`,
			name: "查询执行库存记录详细",
			get: async function (materialStockId) {
				return await http.get(this.url + materialStockId);
			}
		},
		addIssuelineexe: {
			url: `${config.API_URL}/mes/wm/issuelineexe`,
			name: "新增执行库存记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateIssuelineexe: {
			url: `${config.API_URL}/mes/wm/issuelineexe`,
			name: "修改执行库存记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delIssuelineexe: {
			url: `${config.API_URL}/mes/wm/issuelineexe/`,
			name: "删除执行库存记录",
			delete: async function (exeLindId) {
				return await http.delete(this.url + exeLindId);
			}
		},
		delissueIdexe: {
			url: `${config.API_URL}/mes/wm/issuelineexe/issueIdexe`,
			name: "根据issue删除执行领料单行",
			delete: async function (exeLindId) {
				return await http.delete(this.url ,exeLindId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/issuelineexe/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "issuelineexe");
			}
		},
		listExe: {
			url: `${config.API_URL}/mes/wm/issuelineexe/listExe`,
			name: "查询执行库列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		batchListExe: {
			url: `${config.API_URL}/mes/wm/issuelineexe/batchListExe`,
			name: "查询批量执行库列表",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
	},
	stocktaking: {
		listStocktaking: {
			url: `${config.API_URL}/mes/wm/stocktaking/list`,
			name: "查询库存盘点记录列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getStocktaking: {
			url: `${config.API_URL}/mes/wm/stocktaking/`,
			name: "查询库存盘点记录详细",
			get: async function (materialStockId) {
				return await http.get(this.url + materialStockId);
			}
		},
		addStocktaking: {
			url: `${config.API_URL}/mes/wm/stocktaking`,
			name: "新增库存盘点记录",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updateStocktaking: {
			url: `${config.API_URL}/mes/wm/stocktaking`,
			name: "修改库存盘点记录",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delStocktaking: {
			url: `${config.API_URL}/mes/wm/stocktaking/`,
			name: "删除库存盘点记录",
			delete: async function (exeLindId) {
				return await http.delete(this.url + exeLindId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/stocktaking/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "库存盘点记录");
			}
		},
		executeSave: {
			url: `${config.API_URL}/mes/wm/stocktaking/executeSave`,
			name: "执行盘点-保存",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		executeTaking: {
			url: `${config.API_URL}/mes/wm/stocktaking/execute`,
			name: "执行盘点-提交",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		finishedTaking: {
			url: `${config.API_URL}/mes/wm/stocktaking/finishedTaking/`,
			name: "结束盘点",
			put: async function (takingId) {
				return await http.put(this.url + takingId);
			}
		}
	},
	pickSetting: {
		listPickSetting: {
			url: `${config.API_URL}/mes/wm/picking/setting/list`,
			name: "查询领料配置列表",
			get: async function (data) {
				return await http.get(this.url, data);
			}
		},
		getPickSetting: {
			url: `${config.API_URL}/mes/wm/picking/setting/`,
			name: "查询领料配置详细",
			get: async function (id) {
				return await http.get(this.url + id);
			}
		},
		addPickSetting: {
			url: `${config.API_URL}/mes/wm/picking/setting`,
			name: "新增领料配置",
			post: async function (data) {
				return await http.post(this.url, data);
			}
		},
		updatePickSetting: {
			url: `${config.API_URL}/mes/wm/picking/setting`,
			name: "修改领料配置",
			put: async function (data) {
				return await http.put(this.url, data);
			}
		},
		delPickSetting: {
			url: `${config.API_URL}/mes/wm/picking/setting/`,
			name: "删除领料配置",
			delete: async function (id) {
				return await http.delete(this.url + id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/picking/setting/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "picking_setting");
			}
		}
	},
	packing: {
		listPacking: {
			url: `${config.API_URL}/mes/wm/packing/list`,
			name: "查询打包单主表列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getPacking: {
			url: `${config.API_URL}/mes/wm/packing/`,
			name: "查询打包单主表详细",
			get: async function(packingId) {
				return await http.get(this.url +packingId);
			}
		},
		addPacking: {
			url: `${config.API_URL}/mes/wm/packing`,
			name: "新增打包单主表",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updatePacking: {
			url: `${config.API_URL}/mes/wm/packing`,
			name: "修改打包单主表",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delPacking: {
			url: `${config.API_URL}/mes/wm/packing/`,
			name: "删除打包单主表",
			delete: async function(packingId) {
				return await http.delete(this.url +packingId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/packing/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "packing");
			}
		},
		getPackingLabel: {
			url: `${config.API_URL}/mes/wm/packing/getPackingLabel`,
			name: "查询标签",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
	},
	packingBatch: {
		listPacking: {
			url: `${config.API_URL}/mes/wm/packing/batch/list`,
			name: "查询打包单主表列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getPacking: {
			url: `${config.API_URL}/mes/wm/packing/batch/`,
			name: "查询打包单主表详细",
			get: async function(packingId) {
				return await http.get(this.url +packingId);
			}
		},
		addPacking: {
			url: `${config.API_URL}/mes/wm/packing/batch`,
			name: "新增批量打包",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updatePacking: {
			url: `${config.API_URL}/mes/wm/packing/batch`,
			name: "修改批量打包",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delPacking: {
			url: `${config.API_URL}/mes/wm/packing/batch/`,
			name: "删除打包单主表",
			delete: async function(packingId) {
				return await http.delete(this.url +packingId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/packing/batch/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "packing-batch");
			}
		},
		getPackingLabel: {
			url: `${config.API_URL}/mes/wm/packing/batch/getPackingLabel`,
			name: "查询标签",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
	},
	wmInSetting:{
		listSetting: {
			url: `${config.API_URL}/mes/wm/setting/list`,
			name: "查询入库配置列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getSetting: {
			url: `${config.API_URL}/mes/wm/setting/`,
			name: "查询入库配置详细",
			get: async function(id) {
				return await http.get(this.url +id);
			}
		},
		addSetting: {
			url: `${config.API_URL}/mes/wm/setting`,
			name: "新增入库配置",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateSetting: {
			url: `${config.API_URL}/mes/wm/setting`,
			name: "修改入库配置",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delSetting: {
			url: `${config.API_URL}/mes/wm/setting/`,
			name: "删除入库配置",
			delete: async function(id) {
				return await http.delete(this.url +id);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/wm/setting/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "setting");
			}
		},

	},
	label:{
		listLabel: {
			url: `${config.API_URL}/mes/barcodeLabel/label/list`,
			name: "查询打印标签列表",
			get: async function(data) {
				return await http.get(this.url, data);
			}
		},
		getLabel: {
			url: `${config.API_URL}/mes/barcodeLabel/label/`,
			name: "查询打印标签详细",
			get: async function(labelId) {
				return await http.get(this.url +labelId);
			}
		},
		addLabel: {
			url: `${config.API_URL}/mes/barcodeLabel/label`,
			name: "新增打印标签",
			post: async function(data) {
				return await http.post(this.url ,data);
			}
		},
		updateLabel: {
			url: `${config.API_URL}/mes/barcodeLabel/label`,
			name: "修改打印标签",
			put: async function(data) {
				return await http.put(this.url ,data);
			}
		},
		delLabel: {
			url: `${config.API_URL}/mes/barcodeLabel/label/`,
			name: "删除打印标签",
			delete: async function(labelId) {
				return await http.delete(this.url +labelId);
			}
		},
		exportFile: {
			url: `${config.API_URL}/mes/barcodeLabel/label/export`,
			name: "导出附件",
			post: async function (data, config = {}) {
				return await http.postDom(this.url, data, config, "`label`");
			}
		},
	}
}
